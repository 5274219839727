import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Bondi Empanadas',
    component: Home,
    meta: {
      description: 'BONDI EMPANADERIA & BAR - Rue des Côtes-de-Montbenon 5, 1003 Lausanne (Flon), Suisse. Commander vos empanadas',
    }
  },
  {
    path: '/contact',
    name: 'Contact - Bondi Empanadas',
    component: Home,
    meta: {
      description: 'Les empanadas, une solution idéale et conviviale pour les événements et les grandes tablées. Commander des empanadas en tant que privé ou professionnel.',
    }
  },
  {
    path: '/about',
    name: 'About - Bondi Empanadas',
    component: Home,
    meta: {
      description: 'Rue des Côtes-de-Montbenon 5, 1003 Lausanne (Flon), Suisse. Commander vos empanadas',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
