<template>
  <div id="">
    <section
      class="section--zone"
      v-for="(zone, i) in zones"
      :key="i"
      :style="zone.styles"
      @mouseover="curentZoneKey = i"
    ></section>
    <section class="section section--content">
      <div @click="curentZoneKey = Math.floor(Math.random() * 4)">
        <img src="@/assets/img/logo-bondi-new.svg" alt="Bondi - Empanaderia Bar">
        <h1>Empanadas argentines</h1>
      </div>
    </section>
    <section class="section section--credits">
      <a href="https://www.instagram.com/bondi_empanadas" target="_blank">
        <img src="@/assets/img/instagram.svg" alt="Instagram">
      </a>
      <div>
          © {{(new Date()).getFullYear()}} Fayabash Entertainment Sarl – design by Lisa Bernasconi & web by
          <a href="https://wgr.ch" target="_blank">
            <svg class="logo--wgr" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 457.78 151.66">
              <path fill="#FFFFFF" d="M427.59,49.9c0,11.49-8.65,20.64-21.44,20.64H358.43V28.86H406c13,0,21.63,9.53,21.63,21m30.19,98.29c-3.24-4.77-31.49-51-34.47-55.78,16.22-4.1,31.41-21,31.41-42.51,0-26-19.56-46.43-46-46.43H332.39V148.19h26V95.68h35.1c3.29,5.45,28.88,47.92,31.72,52.51Z"/>
              <path fill="#FFFFFF" d="M313.28,75.06a62.6,62.6,0,0,0-.22-6.73H235.38V92.41h46.68a43.49,43.49,0,0,1-42.76,33.88c-26.68,0-47.76-22.37-47.76-50.37,0-27.3,20.18-50.08,47.94-50.08,15.23,0,27.39,7.59,35.41,16.9,3.47-3.25,16.45-15.82,19.47-18.63A74.76,74.76,0,0,0,239.75,0c-43.62,0-75.34,35.37-75.34,75.92,0,41.68,32.58,75.74,74.71,75.74s74.16-34.29,74.16-76.6"/>
              <path fill="#FFFFFF" d="M153.38,3.49H125.21c-.22,8.47-8.74,84.8-9.37,91.29-4.1-3.47-34.24-34.72-39.24-39.27-4.73,4.33-34.07,34.74-39.29,39.27-.41-8.88-8.88-83-9.06-91.29H0c.67,9.33,15.68,134.7,16.49,148.17,7.43-6.08,54.07-54.29,60.11-59.72,6.08,5.2,54.65,55.17,60.33,59.72.45-8.24,15.63-139.29,16.45-148.17"/>
            </svg>
          </a>
      </div>
    </section>
  </div>
</template>
<script>
import * as PIXI from 'pixi.js';
import { Stage, Layer } from '@pixi/layers';

export default {
  name: "",
  data: function(){
    return {
      zones: [
        {
          styles: { top: '0%', left: '0%', width: '0%', height: '0%' },
          sprite: '/img/illu-3-phone.png'
        },
        {
          styles: { top: '0%', left: '20%', width: '0%', height: '0%' },
          sprite: '/img/illu-1-phone.png'
        },
        {
          styles: { top: '0%', left: '80%', width: '0%', height: '0%', },
          sprite: '/img/illu-2-phone.png'
        },
        {
          styles: { top: '25%', left: '20%', width: '0%', height: '0%', },
          sprite: '/img/illu-5-phone.png'
        },
        {
          styles: { top: '75%', left: '20%', width: '0%', height: '0%', },
          sprite: '/img/illu-4-phone.png'
        },
        {
          styles: { top: '0%', left: '0%', width: '20%', height: '100%' },
          sprite: '/img/illu-3-mob.png'
        },
        {
          styles: { top: '0%', left: '20%', width: '60%', height: '25%' },
          sprite: '/img/illu-1-mob.png'
        },
        {
          styles: { top: '0%', left: '80%', width: '20%', height: '100%', },
          sprite: '/img/illu-2-mob.png'
        },
        {
          styles: { top: '25%', left: '20%', width: '60%', height: '50%', },
          sprite: '/img/illu-5-mob.png'
        },
        {
          styles: { top: '75%', left: '20%', width: '60%', height: '25%', },
          sprite: '/img/illu-4-mob.png'
        },
      ],
      vw: window.innerWidth,
      vh: window.innerHeight,
      curentZoneKey: Math.floor(Math.random() * 4)
    }
  },
  methods: {
    pixiInit(){

      document.documentElement.addEventListener("mousemove", function (e) {
          this.N = { x: e.clientX, y: e.clientY };
      });

      this.traceCanvas = document.createElement("canvas")
      this.traceCanvasCtx = this.traceCanvas.getContext("2d")
      this.traceCanvasCtx.canvas.width = this.vw
      this.traceCanvasCtx.canvas.height = this.vh

      this.pixiApp = new PIXI.Application({width: this.vw, height: this.vh, transparent: 1,resolution: 2, autoDensity: true, antialias: false})

      document.body.appendChild(this.pixiApp.view);

      this.pixiApp.stage = new Stage()
      this.pixiApp.stage.interactive = 1

      this.trailLayer = new Layer();
      this.trailLayer.useRenderTexture = true;
      this.trailLayer.useDoubleBuffer = true;

      this.trailSprite = new PIXI.Sprite(this.trailLayer.getRenderTexture());
      this.trailSprite.alpha = 1;

      this.trailLayer.addChild(this.trailSprite);

      this.pixiApp.stage.addChild(this.trailLayer);
      this.showLayer = new PIXI.Sprite(this.trailLayer.getRenderTexture());
      this.pixiApp.stage.addChild(this.showLayer);

      this.spriteTextures = []
      for(let i = 0;i < this.zones.length;i++){
        this.spriteTextures[i] = new PIXI.Texture.from(this.zones[i].sprite);
      }

      this.spriteContainer = new PIXI.Container();
      this.spriteContainer.position.set(0, 0);

      this.sprite = new PIXI.Sprite(this.spriteTextures[this.curentZoneKey])
      this.sprite.x = -500;
      this.sprite.y = -500;
      this.sprite.scale.set((this.vw > 767)? 0.7 : 1);
      this.sprite.anchor.set(0.5);
      this.spriteContainer.addChild(this.sprite)

      let comp = this
      this.pixiApp.stage.on("pointermove", function(e){
        let pos = e.data.global;
        comp.changeTexture()
        comp.sprite.x = pos.x
        comp.sprite.y = pos.y
      })

      this.trailLayer.addChild(this.spriteContainer);
    },
    changeTexture() {
      if(this.sprite) this.sprite.texture = this.spriteTextures[this.curentZoneKey]
    },
  },
  mounted() {
    this.pixiInit()
  }
}
</script>
