<template>
  <section class="section section--overlay" :class="{active: active}">
    <vue-headful :title="$route.name" :description="$route.meta.description" />
    <div class="section__button section__button--bottom">
      <a href="https://www.lacanoche.ch" target="_blank">BONDI x LA CANOCHE</a>
    </div>
    <div class="section__button section__button--right" @click="goTo('/contact')">
      <span>{{ (active && $route.path == '/contact') ? 'BONDI' : 'Contact'}}</span>
    </div>
    <div class="section__button section__button--left">
      <a href="https://www.instagram.com/bondi_empanadas" target="_blank">INSTAGRAM</a>
    </div>
    <div class="section__button section__button--top" @click="goTo('/about')">
      {{ (active && $route.path == '/about') ? 'BONDI' : 'About'}}
    </div>
    <div id="content" class="section__content">
      <practical v-if="active && $route.path == '/about'"/>
      <book v-if="active && $route.path == '/contact'" />
    </div>
  </section>
</template>
<script>
import book from '@/views/elements/Book.vue';
import practical from '@/views/elements/Practical.vue';

export default {
  name: "",
  components: {
    book,
    practical
  },
  data: () => ({
    active: 0,
  }),
  methods: {
    toggleActive() {
      this.active = this.$route.path != '/'
    },
    goTo(path) {
      if(path == this.$route.path) {
        this.active = false
        this.$router.push('/')
      } else {
        this.$router.push(path)
      }
    }
  },
  created(){
    this.toggleActive()
  },
  watch: {
    '$route': function(){
      this.toggleActive()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
